import React from 'react'
import Layout from '../components/Layout'
import Button from '../components/Button'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'

const Thanks = () => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className="max-w-screen-xl mx-auto mt-10 p-20">
        <Link
          className="absolute top-0 right-0 p-4 text-red text-sm uppercase tracking-widest font-semibold"
          to={closeTo}
        >
          Close X
        </Link>
        <div className="mx-auto mt-10 p-5 max-w-3xl">
          <h1 className="h1">Terms and Conditions</h1>
          <p className="mt-5 mb-10">Accept Terms of Use</p>
          <p className="mt-5 mb-10">
            The MUSEUM OF FOOD AND DRINK (MOFAD) ITS STAFF, BOARD, OFFICERS,
            TRUSTEES AND AFFILIATED PERSONS DISCLAIM ALL EXPRESS AND IMPLIED
            WARRANTIES WITH REGARD TO THE INFORMATION, SERVICES, AND MATERIALS
            CONTAINED ON THIS SITE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NONINFRINGEMENT. YOUR USE OF THIS SITE IS AT YOUR OWN RISK. ACCESS
            TO THIS SITE MAY BE INTERRUPTED AND INFORMATION, SERVICES, AND
            MATERIALS MAY NOT BE ERROR-FREE.
          </p>

          <p className="mt-5 mb-10">
            NEITHER MOFAD, ITS STAFF, BOARD, OFFICERS, TRUSTEES OR ANY
            AFFILIATED PERSON OF ANY OF THE FOREGOING, NOR ANYONE ELSE INVOLVED
            IN CREATING, PRODUCING, OR DELIVERING THIS SITE OR THE INFORMATION,
            SERVICES, AND MATERIALS CONTAINED ON THIS SITE ASSUMES ANY LIABILITY
            OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS, OR USEFULNESS OF
            ANY INFORMATION, SERVICES, AND MATERIALS PROVIDED ON THIS SITE, NOR
            SHALL THEY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF YOUR USE OF, OR
            INABILITY TO USE, THIS SITE. ALL INFORMATION, SERVICES, AND
            MATERIALS ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY
            OF ANY KIND, EXPRESS OR IMPLIED.
          </p>

          <p className="mt-5 mb-10">
            IN NO EVENT SHALL MOFAD, ITS STAFF, BOARD, OFFICERS, TRUSTEES OR ANY
            AFFILIATED PERSON OF ANY OF THE FOREGOING BE LIABLE FOR ANY DIRECT,
            INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
            OR ANY DAMAGES WHATSOEVER, RESULTING FROM THE USE OF, OR INABILITY
            TO USE, THE INFORMATION, SERVICES, OR MATERIALS ON THIS SITE,
            WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
            PRODUCT LIABILITY, OR OTHERWISE, EVEN IF ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES.
          </p>

          <p className="mt-5 mb-10">
            BY USING THIS WEBSITE, YOU ACKNOWLEDGE AND AGREE THAT THE
            LIMITATIONS SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THESE TERMS
            OF USE AND THIS SITE WOULD NOT BE PROVIDED TO YOU WITHOUT THOSE
            LIMITATIONS. SOME STATE LAWS REGARDING LIMITATION OF LIABILITY MIGHT
            APPLY.
          </p>

          <p className="mt-5 mb-10">
            ANY AND ALL SUBMISSIONS CONTAINING PERSONAL INFORMATION ABOUT A
            CHILD UNDER THE AGE OF THIRTEEN MUST BE MADE BY A PARENT OR OTHER
            LEGAL GUARDIAN OF SUCH CHILD AND MUST NOT INCLUDE THE LAST NAME OF,
            OR ANY CONTACT INFORMATION FOR, THE CHILD. THIS SITE IS NOT A WEB
            SITE OR OTHER ONLINE SERVICE DIRECTED TO CHILDREN UNDER THE AGE OF
            THIRTEEN (13) AND ACCORDINGLY IS NOT INTENDED TO BE SUBJECT TO THE
            CHILDREN’S ONLINE PRIVACY PROTECTION ACT OF 1998, AS AMENDED.
          </p>

          <p className="mt-5 mb-10">
            You agree to indemnify, defend, and hold harmless MOFAD, its staff,
            board, officers and trustees from any liability, loss, claim, and
            expense (including attorneys' fees) related to (i) your violation of
            this Terms of Use of this website and (ii) your posting of material
            to this site.
          </p>

          <div className="text-center">
            <Link to="/">
              <Button>Browse the Quilt</Button>
            </Link>
          </div>
        </div>
      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default Thanks
